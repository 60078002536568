let sliderHome = {
  init: function () {

    if ( document.querySelectorAll( '.js-slider-home' ).length < 1 ) return;

    let slider1 = new Glide( document.querySelector( '.js-slider-home' ), {
      perView: 1,
      autoplay: 4000,
      gap: 0,
      breakpoints: {
        800: {
          dragThreshold: true,
          swipeThreshold: true,
          perTouch: true,
        }
      }
    } ).mount();

    let slider2 = new Glide( document.querySelector( '.js-slider-home-mini' ), {
      perView: 1,
      swipeThreshold: false,
      dragThreshold: false,
      bound: true,
      breakpoints: { 
        800: {
          dragThreshold: true,
          swipeThreshold: true,
          perTouch: true,
        }
      }
    } ).mount();

    slider1.on( 'swipe.end', function () {
      slider2.go( '=' + ( slider1.index ) )
    } )

    slider1.on( 'move.after', function () {
      slider2.go( '=' + ( slider1.index ) )
    } )
  }
}