let nav = {
    init: function () {
        let navToggles = document.querySelectorAll( '.js-nav-toggle' );

        for ( var i = 0; i < navToggles.length; i++ ) {
            navToggles[ i ].onclick = function ( e ) {
                document.body.classList.toggle( 'body-overflow' )
            }
        }

        document.onkeydown = function(evt) {
            evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc");
            } else {
                isEscape = (evt.keyCode === 27);
            }
            if (isEscape) {
                document.body.classList.remove( 'body-overflow' )
            }
        };


    }
}