let sliderMulti = {
    init: function () {
        if ( document.querySelectorAll( '.js-slider-multi' ).length < 1 ) return;

       let multi = new Glide( document.querySelector( '.js-slider-multi' ), {
            perView: 6,
            gap: 0,
            type: 'carousel',
            autoplay: 1,
            animationDuration: 8000,
            animationTimingFunc: 'linear',
            swipeThreshold: false, 
            dragThreshold: false,
            perTouch: false,
            breakpoints: {
                800: {
                    perView: 1.5,
                    autoplay: 0,
                    animationDuration: 300,
                    animationTimingFunc: 'ease-out',
                    dragThreshold: true,
                    swipeThreshold: true,
                    perTouch: true,
                },
            }
        } ).mount();

        
    }
}