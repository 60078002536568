let slider = {
  init: function () {

    if ( document.querySelectorAll( '.js-slider' ).length < 1 ) return;

    let slider = new Glide( document.querySelector( '.js-slider' ), {
      type: 'carousel',
      perView: 1,
      autoplay: 2000,
      gap: 0,
    } ).mount();
  }
}